import React, { lazy, Suspense } from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';


const PageHome = lazy(() => import('../Components/Pages/PagesHome/PageHome'));
const PageHomeIn = lazy(() => import('../Components/Pages/PagesHomeIn/PageHomeIn'));
const PageRegister = lazy(() => import('../Components/Pages/PageRegister/PageRegister'));
const PagePassword = lazy(() => import('../Components/Pages/PagePassword/PagePassword'));
const PagePqrs = lazy(() => import('../Components/Pages/PagePqrs/PagePqrs'));
const PageExternalQuote = lazy(() => import('../Components/Pages/PageExternalQuote/PageExternalQuote'));
const TemplateWellbeing = lazy(() => import('../Components/Templates/TemplateWellbeing/TemplateWellbeing'));
const PageClub = lazy(() => import('../Components/Pages/PageClub/PageClub'));
const PageFaq = lazy(() => import('../Components/Pages/PageFaq/PageFaq'));
const EpaycoWebHub = lazy(() => import('../Components/Pages/PageEpaycoExitoso/EpaycoWebHub'));
const validation = localStorage.getItem('id_usuario');


export const Routes = createBrowserRouter([
    {
        path: '/',
        element: <Suspense fallback={<div>...</div>}><PageHome /></Suspense>
    },
    {
        path: '/inicio',
        element: validation ? <Suspense fallback={<div>...</div>}><PageHomeIn eventKeyPage={'inicio'}/></Suspense> : <Navigate to='/' />
    },
    {
        path: '/perfil',
        element: validation ? <Suspense fallback={<div>...</div>}><PageHomeIn eventKeyPage={'perfil'}/></Suspense> : <Navigate to='/' />
    },
    {
        path: '/cotizar',
        element: validation ? <Suspense fallback={<div>...</div>}><PageHomeIn eventKeyPage={'cotizar'}/></Suspense> : <Navigate to='/' />
    },
    {
        path: '/emitir',
        element: validation ? <Suspense fallback={<div>...</div>}><PageHomeIn eventKeyPage={'emitir'}/></Suspense> : <Navigate to='/' />
    },
    {
        path: '/reporte-ventas',
        element: validation ? <Suspense fallback={<div>...</div>}><PageHomeIn eventKeyPage={'reporte-ventas'}/></Suspense> : <Navigate to='/' />
    },
    {
        path: '/registro',
        element: <Suspense fallback={<div>...</div>}><PageRegister /></Suspense>
    },
    {
        path: '/contrasena',
        element: <Suspense fallback={<div>...</div>}><PagePassword /></Suspense>
    },
    {
        path: '/pqrs',
        element: <Suspense fallback={<div>...</div>}><PagePqrs /></Suspense>
    },
    {
        path: '/bienestar',
        element: <Suspense fallback={<div>...</div>}><TemplateWellbeing /></Suspense>
    },
    {
        path: '/club-amigos-mok',
        element: <Suspense fallback={<div>...</div>}><PageClub /></Suspense>
    },
    {
        path: '/preguntas-frecuentes',
        element: <Suspense fallback={<div>...</div>}><PageFaq /></Suspense>
    },
    {
        path: '/pagorealizado',
        element: <Suspense fallback={<div>...</div>}><EpaycoWebHub /></Suspense>
    },
    {
        path: '/cotizacion',
        element: <Suspense fallback={<div>...</div>}><PageExternalQuote /></Suspense>
    },
]);