import LanguageES from '../../Locales/es-co.json';
import LanguageEN from '../../Locales/en-us.json';


const GetLanguages = () =>{

    let langOS = navigator.language || navigator.userLanguage;
    const languages = window.localStorage.getItem('langs') ? JSON.parse(window.localStorage.getItem('langs')) : langOS === 'es' ? LanguageES : langOS === 'en' ? LanguageEN : {};

    let lang;
    if (window.localStorage.getItem('langs') != null) {
        switch (langOS) {
            case "en":
                lang = languages.en;
                break;
            case "es":
                lang = languages.es;
                break;
            default:
                lang = languages.en;
                break;
        };
        return lang;
    } else {
        switch (langOS) {
            case "en":
                lang = LanguageEN;
                break;
            case "es":
                lang = LanguageES;
                break;
            default:
                lang = LanguageEN;
                break;
        };
        setTimeout(() => {
            window.location.reload();
        }, 9995000);

        return lang;
    }
};

export default GetLanguages;